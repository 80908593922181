import { get, post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const clientIndex = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/order`, page, perPage, search, sortBy, descending, params);


export const autocomplete = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/order`, page, perPage, search, sortBy, descending, params);

export const show = (id) => get(`order/${id}`);

export const updateOrder = (id, values) => post(`order/${id}/save`, values);

export const sendProposal = (id) => post(`order/${id}/send-offer`);

export const exportOrders = (params) => post(`order/export`, params, { responseType: 'blob' });
